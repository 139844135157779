<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 查询表单 -->
    <el-form :inline="true" :model="queryForm">
      <el-form-item>
        <el-input v-model="queryForm.userName" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="queryForm.realName"
          placeholder="真实姓名"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryForm.phone" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="queryForm.roleId" placeholder="角色" clearable>
          <el-option
            v-for="item in roleOptions"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="$auth($authData.system.userGet)"
          type="primary"
          icon="el-icon-search"
          @click="queryData"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="$auth($authData.system.userAdd)"
          type="success"
          icon="el-icon-plus"
          @click="initAddForm"
          >添加</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 数据表 -->
    <el-table
      ref="userTable"
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
    >
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column
        prop="userName"
        label="用户名"
        min-width="150"
      ></el-table-column>
      <el-table-column
        prop="realName"
        label="真实姓名"
        min-width="150"
      ></el-table-column>
      <el-table-column
        prop="sex"
        label="性别"
        width="60"
        header-align="center"
        align="center"
        :formatter="sexFormatter"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="手机号"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="email"
        label="邮箱"
        min-width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="160"
        sortable
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="更新时间"
        width="160"
        sortable
      ></el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="250"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$auth($authData.system.userGet)"
            size="mini"
            type="text"
            icon="el-icon-search"
            @click="queryDetail(scope.row)"
            >详情</el-button
          >
          <el-button
            v-if="$auth($authData.system.userEdit)"
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="initEditForm(scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="$auth($authData.system.userEditPassword)"
            size="mini"
            type="text"
            icon="el-icon-key"
            @click="initEditPassword(scope.row)"
            >修改密码</el-button
          >
          <el-button
            v-if="$auth($authData.system.userDelete)"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="deleteData(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page.sync="queryForm.pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size.sync="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="queryData"
      @current-change="queryData"
      @prev-click="queryData"
      @next-click="queryData"
    >
    </el-pagination>
    <!-- 业务弹框-添加用户 -->
    <el-dialog
      title="添加用户"
      :visible.sync="dialogAdd"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        ref="userAddForm"
        :model="userAddForm"
        :rules="userRules"
        status-icon
        label-position="top"
        size="medium"
      >
        <el-form-item label="用户名" prop="userName">
          <el-tooltip
            content="用户名必须为4~16位字母或数字"
            placement="right"
            effect="light"
          >
            <el-input
              v-model="userAddForm.userName"
              placeholder="请输入用户名"
              maxlength="16"
              show-word-limit
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-tooltip
            content="真实姓名必须为中文、字母或数字，且不超过20位"
            placement="right"
            effect="light"
          >
            <el-input
              v-model="userAddForm.realName"
              placeholder="请输入真实姓名"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-tooltip
            content="密码必须6~12位字符，且不能出现不可见字符"
            placement="right"
            effect="light"
          >
            <el-input
              v-model="userAddForm.password"
              type="password"
              placeholder="请输入密码"
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePassword">
          <el-input
            v-model="userAddForm.rePassword"
            type="password"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="userAddForm.sex">
            <el-option
              v-for="item in sexOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="userAddForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="userAddForm.email"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="userAddForm.roleId">
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否可用" prop="isEnabled">
          <el-select v-model="userAddForm.isEnabled">
            <el-option
              v-for="item in enabledOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="addLoading" @click="addData"
          >确定</el-button
        >
        <el-button @click="dialogAdd = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 业务弹框-用户详情 -->
    <el-dialog
      title="用户详情"
      :visible.sync="dialogDetail"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-descriptions
        v-loading="detailLoading"
        :column="1"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-descriptions-item label="用户ID">
          {{ detailData.id }}
        </el-descriptions-item>
        <el-descriptions-item label="用户名">
          {{ detailData.userName }}
        </el-descriptions-item>
        <el-descriptions-item label="真实姓名">
          {{ detailData.realName }}
        </el-descriptions-item>
        <el-descriptions-item label="性别">
          {{ this.sexFormatter(detailData) }}
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          {{ detailData.phone }}
        </el-descriptions-item>
        <el-descriptions-item label="邮箱">
          {{ detailData.email }}
        </el-descriptions-item>
        <el-descriptions-item label="角色">
          {{ detailData.roleName }}
        </el-descriptions-item>
        <el-descriptions-item label="是否可用">
          {{ this.enabledFormatter(detailData) }}
        </el-descriptions-item>
        <el-descriptions-item label="创建时间">
          {{ detailData.createTime }}
        </el-descriptions-item>
        <el-descriptions-item label="更新时间">
          {{ detailData.updateTime }}
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- 业务弹框-编辑用户 -->
    <el-dialog
      title="编辑用户"
      :visible.sync="dialogEdit"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        ref="userEditForm"
        v-loading="initEditLoading"
        :model="userEditForm"
        :rules="userRules"
        status-icon
        label-position="top"
        size="medium"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-form-item label="用户名">
          <el-input v-model="userEditForm.userName" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="realName">
          <el-tooltip
            content="真实姓名必须为中文、字母或数字，且不超过20位"
            placement="right"
            effect="light"
          >
            <el-input
              v-model="userEditForm.realName"
              placeholder="请输入真实姓名"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="userEditForm.sex">
            <el-option
              v-for="item in sexOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="userEditForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input
            v-model="userEditForm.email"
            placeholder="请输入邮箱"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleId">
          <el-select v-model="userEditForm.roleId">
            <el-option
              v-for="item in roleOptions"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否可用" prop="isEnabled">
          <el-select v-model="userEditForm.isEnabled">
            <el-option
              v-for="item in enabledOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="editLoading" @click="editData"
          >确定</el-button
        >
        <el-button @click="dialogEdit = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 业务弹框-修改用户密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogEditPassword"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        ref="editPasswordForm"
        :model="editPasswordForm"
        :rules="editPasswordRules"
        status-icon
        label-position="top"
        size="medium"
      >
        <el-form-item label="新密码" prop="newPassword">
          <el-tooltip
            content="密码必须6~12位字符，且不能出现不可见字符"
            placement="right"
            effect="light"
          >
            <el-input
              v-model="editPasswordForm.newPassword"
              type="password"
              placeholder="请输入新密码"
            ></el-input
          ></el-tooltip>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePassword">
          <el-input
            v-model="editPasswordForm.rePassword"
            type="password"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          :loading="editPasswordLoading"
          @click="editPassword"
          >确定</el-button
        >
        <el-button @click="dialogEditPassword = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    let vUserName = (rule, value, callback) => {
      this.$http
        .get("/system/user/checkName", { params: { userName: value } })
        .then((response) => {
          if (response.data.code === 200 && response.data.data) {
            callback(new Error("用户名已存在"));
          } else {
            callback();
          }
        });
    };
    let vRePassword = (rule, value, callback) => {
      if (value !== this.userAddForm.password) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    let vEditRePassword = (rule, value, callback) => {
      if (value !== this.editPasswordForm.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      queryForm: {
        userName: null,
        realName: null,
        phone: null,
        roleId: null,
        pageNo: 1,
        pageSize: 10,
      },
      roleOptions: [],
      sexOptions: [
        {
          name: "男",
          value: 1,
        },
        {
          name: "女",
          value: 2,
        },
      ],
      enabledOptions: [
        {
          name: "可用",
          value: 1,
        },
        {
          name: "禁用",
          value: 0,
        },
      ],
      tableData: [],
      total: 0,
      loading: false,
      userRules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]{4,16}$/,
            message: "用户名格式不正确",
            trigger: "blur",
          },
          { validator: vUserName, trigger: "blur" },
        ],
        realName: [
          {
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9]{0,20}$/,
            message: "真实姓名格式不正确",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[\S]{6,12}$/,
            message: "密码格式不正确",
            trigger: "blur",
          },
        ],
        rePassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: vRePassword, trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        phone: [
          {
            pattern: /^1\d{10}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            message: "邮箱格式不正确",
            trigger: "blur",
          },
        ],
        roleId: [{ required: true, message: "请选择角色", trigger: "change" }],
        isEnabled: [
          { required: true, message: "请选择是否可用", trigger: "change" },
        ],
      },
      dialogAdd: false,
      userAddForm: {},
      addLoading: false,
      dialogDetail: false,
      detailLoading: false,
      detailData: {},
      dialogEdit: false,
      userEditForm: {},
      initEditLoading: false,
      editLoading: false,
      dialogEditPassword: false,
      editPasswordForm: {},
      editPasswordRules: {
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[\S]{6,12}$/,
            message: "密码格式不正确",
            trigger: "blur",
          },
        ],
        rePassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: vEditRePassword, trigger: "blur" },
        ],
      },
      editPasswordLoading: false,
    };
  },
  mounted() {
    this.queryData();
    this.queryRole();
  },
  methods: {
    queryData() {
      this.loading = true;
      this.$http
        .get("/system/user/getPage", { params: this.queryForm })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    queryRole() {
      this.$http.get("/system/role/getAll").then((response) => {
        if (response.data.code === 200) {
          this.roleOptions = response.data.data;
        }
      });
    },
    sexFormatter(row) {
      for (let i = 0, len = this.sexOptions.length; i < len; i++) {
        let item = this.sexOptions[i];
        if (row.sex === item.value) {
          return item.name;
        }
      }
      return null;
    },
    enabledFormatter(row) {
      for (let i = 0, len = this.enabledOptions.length; i < len; i++) {
        let item = this.enabledOptions[i];
        if (row.isEnabled === item.value) {
          return item.name;
        }
      }
      return null;
    },
    queryDetail(row) {
      this.detailLoading = true;
      this.$http
        .get("/system/user/get", { params: { id: row.id } })
        .then((response) => {
          if (response.data.code === 200) {
            this.detailData = response.data.data;
          }
        })
        .finally(() => {
          this.detailLoading = false;
        });
      this.dialogDetail = true;
    },
    initAddForm() {
      this.userAddForm = {
        userName: null,
        realName: null,
        password: null,
        rePassword: null,
        sex: 1,
        phone: null,
        email: null,
        roleId: null,
        isEnabled: 1,
      };
      this.addLoading = false;
      this.dialogAdd = true;
    },
    addData() {
      this.$refs["userAddForm"].validate((valid) => {
        if (valid) {
          this.addLoading = true;
          let addData = {
            userName: this.userAddForm.userName,
            realName: this.userAddForm.realName,
            password: this.$encodePassword(this.userAddForm.password),
            rePassword: this.$encodePassword(this.userAddForm.rePassword),
            sex: this.userAddForm.sex,
            phone: this.userAddForm.phone,
            email: this.userAddForm.email,
            roleId: this.userAddForm.roleId,
            isEnabled: this.userAddForm.isEnabled,
          };
          this.$http
            .post("/system/user/add", addData)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogAdd = false;
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1000,
                  onClose: () => {
                    this.queryData();
                  },
                });
              }
            })
            .finally(() => {
              this.addLoading = false;
            });
        }
      });
    },
    initEditForm(row) {
      this.initEditLoading = true;
      this.$http
        .get("/system/user/get", { params: { id: row.id } })
        .then((response) => {
          if (response.data.code === 200) {
            let retData = response.data.data;
            this.userEditForm = {
              id: retData.id,
              userName: retData.userName,
              realName: retData.realName,
              sex: retData.sex,
              phone: retData.phone,
              email: retData.email,
              roleId: retData.roleId,
              isEnabled: retData.isEnabled,
            };
          }
        })
        .finally(() => {
          this.initEditLoading = false;
        });
      this.editLoading = false;
      this.dialogEdit = true;
    },
    editData() {
      this.$refs["userEditForm"].validate((valid) => {
        if (valid) {
          this.editLoading = true;
          this.$http
            .post("/system/user/edit", this.userEditForm)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogEdit = false;
                this.$message({
                  message: "编辑成功",
                  type: "success",
                  duration: 1000,
                  onClose: () => {
                    this.queryData();
                  },
                });
              }
            })
            .finally(() => {
              this.editLoading = false;
            });
        }
      });
    },
    initEditPassword(row) {
      this.editPasswordForm = {
        userId: row.id,
        newPassword: null,
        rePassword: null,
      };
      this.editPasswordLoading = false;
      this.dialogEditPassword = true;
    },
    editPassword() {
      this.$refs["editPasswordForm"].validate((valid) => {
        if (valid) {
          this.editPasswordLoading = true;
          let editSelfPassword = {
            userId: this.editPasswordForm.userId,
            newPassword: this.$encodePassword(
              this.editPasswordForm.newPassword
            ),
            rePassword: this.$encodePassword(this.editPasswordForm.rePassword),
          };
          this.$http
            .post("/system/user/editPassword", editSelfPassword)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogEditPassword = false;
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 1000,
                });
              }
            })
            .finally(() => {
              this.editPasswordLoading = false;
            });
        }
      });
    },
    deleteData(row) {
      this.$confirm("是否删除该用户？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http
          .delete("/system/user/delete", { params: { id: row.id } })
          .then((response) => {
            if (response.data.code === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 1000,
                onClose: () => {
                  this.queryData();
                },
              });
            }
          });
      });
    },
  },
};
</script>

<style scoped></style>
